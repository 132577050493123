import axios from "../axios";
import {
  ADD_RELATION,
  DELETE_RELATIONS,
  GET_ALL_RELATION,
  HIDE_LOADER,
  SHOW_LOADER,
  UPDATE_RELATIONS,
} from "../../store/actions/actions";
import { setSuccess, setError } from "../../store/actions/actionCreator";
import { NOTIFICATION_MESSAGE, ROUTES } from "../../referenceData";

const getRelations = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  axios
    .get("/relation/all", { params })
    .then((response) => {
      dispatch({
        type: GET_ALL_RELATION,
        payload: {
          relations: response.data.rows,
          totalRecords: response.data.totalRecords,
        },
      });
      dispatch({ type: HIDE_LOADER });
    })
    .catch(() => {
      dispatch({
        type: GET_ALL_RELATION,
        payload: {
          relations: [],
          totalRecords: 0,
        },
      });
      dispatch({ type: HIDE_LOADER });
    });
};
const addRelations = (params) => (dispatch, getState) => {
  dispatch({ type: SHOW_LOADER });
  const payload = {
    consumerId: params.consumerId,
    providerId: params.providerId,
  };
  const config = {
    headers: {
      "content-type": "application/json",
    },
  };

  if (payload.consumerId === payload.providerId) {
    dispatch(setError(NOTIFICATION_MESSAGE.PROVIDER_CONSUMER_DUPLICATE));
    dispatch({ type: HIDE_LOADER });
    return;
  }

  axios
    .post("/relation", payload, config)
    .then((response) => {
      const currState = getState().relations.data;
      currState.push(response.data.response.INSERT_INTO_RELATION);
      dispatch({
        type: ADD_RELATION,
        payload: { relations: currState },
      });

      dispatch(setSuccess(NOTIFICATION_MESSAGE.SUCESSFULLY_INSERTED_RELATION));
      params.history.push(ROUTES.RELATION);
      dispatch({ type: HIDE_LOADER });
    })
    .catch((error) => {
      if (error.response.data === "Already Exists")
        dispatch(setError(NOTIFICATION_MESSAGE.INSERT_FAILED_ALREADY_EXIST));
      else dispatch(setError(NOTIFICATION_MESSAGE.INSERT_FAILED));
      dispatch({ type: HIDE_LOADER });
    });
};

const updateRelations = (newDataItem) => async (dispatch, getState) => {
  const currState = getState().relations.data;
  const currRow = currState.filter((item) => {
    return item.edgeID === newDataItem.edgeID;
  });
  dispatch({ type: SHOW_LOADER });

  const payload = {
    providerId: newDataItem.providerId,
    consumerId: newDataItem.consumerId,
  };
  if (payload.consumerId === payload.providerId) {
    dispatch(setError(NOTIFICATION_MESSAGE.PROVIDER_CONSUMER_DUPLICATE));
    dispatch({ type: HIDE_LOADER });
    return;
  }
  axios
    .patch(`/relation/${newDataItem.edgeId}`, payload)
    .then((response) => {
      if (currRow === response.data) {
        dispatch({ type: HIDE_LOADER });
        return;
      }
      dispatch({
        type: UPDATE_RELATIONS,
        payload: {
          providerId: response.data,
          consumerId: response.data,
          edgeId: newDataItem.edgeId,
        },
      });
      newDataItem.history.push(ROUTES.RELATION);

      if (response.data === "ALREADY_EXIST") {
        dispatch(setError(NOTIFICATION_MESSAGE.UPDATE_FAILED_ALREADY_EXIST));
        dispatch({ type: HIDE_LOADER });
        return;
      }

      dispatch(setSuccess(NOTIFICATION_MESSAGE.RELATION_UPDATED_SUCESSFUL));
      dispatch({ type: HIDE_LOADER });
    })
    .catch(() => {
      setError("Unable to update relation");
      dispatch({ type: HIDE_LOADER });
    });
};

const deleteRelation = (edgeId) => (dispatch, getState) => {
  dispatch({ type: SHOW_LOADER });
  const currentState = getState().relations.data;
  axios
    .delete(`/relation/${edgeId}`)
    .then(() => {
      currentState.splice(
        currentState.findIndex((item) => item.edgeId === edgeId),
        1
      );
      dispatch({
        type: DELETE_RELATIONS,
        payload: {
          data: currentState,
        },
      });
      dispatch(setSuccess(NOTIFICATION_MESSAGE.RELATION_DELETED_SUCESSFUL));
      dispatch({ type: HIDE_LOADER });
    })
    .catch(() => {
      dispatch(setError(NOTIFICATION_MESSAGE.RELATION_DELETED_FAILED));
      dispatch({ type: HIDE_LOADER });
    });
};
export default { getRelations, addRelations, updateRelations, deleteRelation };
