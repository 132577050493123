export const dropDownColumns = [
  {
    field: "accountId",
    header: "AccountId",
    width: 300,
  },
  {
    field: "name",
    header: "Name",
    width: 875,
  },
];
