const getApiHost = () => process.env.REACT_APP_API_HOST;
const getClientID = () => process.env.REACT_APP_CLIENT_ID;
const getRedirectURL = () => process.env.REACT_APP_REDIRECT_URL;
const getOktaURL = () => process.env.REACT_APP_OKTA_URL;

export default {
  getApiHost,
  getClientID,
  getRedirectURL,
  getOktaURL,
};
