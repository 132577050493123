import { combineReducers } from "redux";
import commonReducer from "./commonReducer";
import cronReducer from "./cronReducer";
import nodeReducer from "./nodeReducer";
import notificationReducer from "./notificationReducer";
import relationReducer from "./relationReducer";

const rootReducer = combineReducers({
  nodes: nodeReducer,
  common: commonReducer,
  relations: relationReducer,
  notification: notificationReducer,
  jobs: cronReducer,
});

export default rootReducer;
