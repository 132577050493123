import CategoryMultiSelect from "../../core/ui/MultiSelect";

const nodeColumns = [
  {
    field: "name",
    title: "Name",
    width: 200,
    isSelected: true,
    editable: false,
  },
  {
    field: "accountId",
    title: "Account ID",
    width: 200,
    isSelected: true,
    editable: false,
  },

  {
    field: "salesForceAccountOwnerName",
    title: "Sales Force Account Owner Name",
    width: 200,
    isSelected: true,
    editable: false,
  },
  {
    field: "salesTerritory",
    title: "Sales Territory",
    width: 200,
    isSelected: true,
    editable: false,
  },
  {
    field: "shippingState",
    title: "Shipping State",
    width: 200,
    isSelected: false,
    editable: false,
  },
  {
    field: "industry",
    title: "Industry",
    width: 150,
    isSelected: true,
    editable: false,
  },
  {
    field: "sicDesc",
    title: "SIC DESC",
    width: 200,
    isSelected: true,
    editable: false,
  },
  {
    field: "esAppIndustry",
    title: "ES APP Industry",
    width: 200,
    isSelected: false,
    editable: false,
  },
  {
    field: "esAppCity",
    title: "ES App City",
    width: 200,
    isSelected: false,
    editable: false,
  },
  {
    field: "region2C",
    title: "Region 2 C",
    width: 150,
    isSelected: true,
    editable: false,
  },
  {
    field: "category",
    title: "Category",
    width: 200,
    isSelected: true,
    editable: true,
    customCell: CategoryMultiSelect,
  },
  {
    field: "xcord",
    title: "X",
    width: 100,
    isSelected: false,
    editable: false,
  },
  {
    field: "ycord",
    title: "Y",
    width: 100,
    isSelected: false,
    editable: false,
  },
];

export default nodeColumns;
