import {
  NotificationGroup,
  Notification,
} from "@progress/kendo-react-notification";
// import { size } from "lodash";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { connect } from "react-redux";
import { NOTIFICATION_AUTOCLOSE_TIME } from "../../../referenceData";
import {
  clearError,
  clearNotification,
  clearSuccess,
} from "../../../store/actions/actionCreator";

const FedEdgeNotification = ({
  success,
  error,
  successMessage,
  errorMessage,
  resetNotification,
  closeError,
  closeSuccess,
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      resetNotification();
    }, NOTIFICATION_AUTOCLOSE_TIME);
    return () => clearInterval(timer);
  });

  return (
    <div className="fed-notification-overlay">
      <NotificationGroup>
        {success && (
          <Notification
            type={{
              style: "success",
              icon: true,
            }}
            closable
            onClose={closeSuccess}
          >
            <span>{successMessage}</span>
          </Notification>
        )}
        {error && (
          <Notification
            type={{
              style: "error",
              icon: true,
            }}
            closable
            onClose={closeError}
          >
            <span>{errorMessage}</span>
          </Notification>
        )}
      </NotificationGroup>
    </div>
  );
};

FedEdgeNotification.propTypes = {
  success: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  successMessage: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  resetNotification: PropTypes.func.isRequired,
  closeError: PropTypes.func.isRequired,
  closeSuccess: PropTypes.func.isRequired,
};

const mapStoreToProps = (store) => ({
  success: store.notification.success,
  error: store.notification.error,
  successMessage: store.notification.successMessage,
  errorMessage: store.notification.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  resetNotification: () => dispatch(clearNotification()),
  closeError: () => dispatch(clearError()),
  closeSuccess: () => dispatch(clearSuccess()),
});
export default connect(
  mapStoreToProps,
  mapDispatchToProps
)(FedEdgeNotification);
