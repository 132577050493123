import React from "react";
import PropTypes from "prop-types";

const Button = ({ children, onClick, className, type, isDisabled }) => (
  <button
    type={type ? "submit" : "button"}
    className={isDisabled ? "submit-button-disabled" : className}
    disabled={isDisabled}
    onClick={onClick}
  >
    {children}
  </button>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

Button.defaultProps = {
  onClick: undefined,
  className: undefined,
  type: undefined,
  isDisabled: false,
};

export default Button;
