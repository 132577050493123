import {
  DELETE_RELATIONS,
  GET_ALL_RELATION,
  ADD_RELATION,
  SET_PROVIDER_DROPDOWN,
  SET_CONSUMER_DROPDOWN,
  UPDATE_RELATIONS,
} from "../actions/actions";

const initialState = {
  data: undefined,
  totalRecords: 0,
  providerDropDown: [],
  consumerDropDown: [],
  totalConsumerDropDown: 0,
  totalProviderDropDown: 0,
};

const relationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_RELATION:
      return {
        ...state,
        data: payload.relations,
        totalRecords: payload.totalRecords,
      };
    case ADD_RELATION:
      return {
        ...state,
        data: payload.relations,
        totalRecords: state.totalRecords + 1,
      };
    case SET_PROVIDER_DROPDOWN: {
      return {
        ...state,
        providerDropDown: payload.dropDownList,
        totalProviderDropDown: payload.total,
      };
    }
    case SET_CONSUMER_DROPDOWN:
      return {
        ...state,
        consumerDropDown: payload.dropDownList,
        totalConsumerDropDown: payload.total,
      };
    case UPDATE_RELATIONS: {
      const newData = state.data.map((item) => {
        if (payload.edgeID === item.edgeID) {
          return payload.updateRelations;
        }
        return item;
      });
      return {
        ...state,
        data: newData,
      };
    }
    case DELETE_RELATIONS: {
      return {
        ...state,
        data: payload.data,
      };
    }
    default:
      return state;
  }
};

export default relationReducer;
