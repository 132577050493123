const columns = [
  {
    field: "providerId",
    title: "Provider Sales Force AccountID",
    width: 300,
    isSelected: true,
  },
  {
    field: "providerName",
    title: "Provider Sales Force Account Name",
    width: 300,
    isSelected: true,
  },
  {
    field: "consumerId",
    title: "Consumer Sales Force AccountID",
    width: 300,
    isSelected: true,
  },

  {
    field: "consumerName",
    title: "Consumer Sales Force Account Name",
    width: 300,
    isSelected: true,
  },
  {
    field: "createdBy",
    title: "Created By",
    width: 300,
    isSelected: false,
  },
  {
    field: "createdDate",
    title: "Relationship Create Date",
    width: 300,
    isSelected: false,
  },
  {
    field: "updatedBy",
    title: "UpdatedBy",
    width: 200,
    isSelected: false,
  },
  {
    field: "updatedDate",
    title: "Relationship Updated Date",
    width: 345,
    isSelected: true,
  },
];

export default columns;
