import { GET_ALL_NODES, UPDATE_NODES } from "../actions/actions";

const initialState = {
  data: undefined,
  totalNodes: 0,
  searchString: undefined,
};

const nodeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_NODES:
      return {
        ...state,
        data: payload.nodes,
        totalNodes: payload.totalNodes,
      };
    case UPDATE_NODES: {
      const newData = state.data.map((item) => {
        if (payload.accountId === item.accountId) {
          return payload.updateNode;
        }
        return item;
      });
      return {
        ...state,
        data: newData,
      };
    }
    default:
      return state;
  }
};

export default nodeReducer;
