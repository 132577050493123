import React from "react";
import PropTypes from "prop-types";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { categories } from "../../../referenceData";

const CategoryMultiSelect = ({ onChange, dataItem, field }) => {
  const handleChange = (e) => {
    if (onChange) {
      onChange({
        dataIndex: 0,
        dataItem,
        field,
        syntheticEvent: e.syntheticEvent,
        value: [...e.value],
      });
    }
  };

  const dataValue = dataItem[field] === null ? [] : dataItem[field];

  return (
    <td>
      {dataItem.inEdit ? (
        <MultiSelect
          style={{
            height: "fit-content",
          }}
          autoClose={false}
          data={categories}
          onChange={handleChange}
          value={dataValue}
        />
      ) : (
        <MultiSelect
          style={{
            height: "fit-content",
          }}
          data={categories}
          disabled
          value={dataValue}
        />
      )}
    </td>
  );
};
CategoryMultiSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  dataItem: PropTypes.any.isRequired,
  field: PropTypes.string,
};

CategoryMultiSelect.defaultProps = {
  field: "",
};

CategoryMultiSelect.defaultProps = {
  field: "",
};

export default CategoryMultiSelect;
