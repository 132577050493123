import React from "react";
import { useLocation } from "react-router-dom";
import Layout from "../core/Layout";
import "../../styles/main.css";
import { META } from "../../referenceData";
import EditForm from "../core/ui/EditForm";

const EditPage = () => {
  const location = useLocation();
  return (
    <Layout meta={META.EDIT}>
      <div className="nodegrid" style={{ height: "calc(100vh - 175px)" }}>
        <EditForm data={location.state ? location.state.data : null} />
      </div>
    </Layout>
  );
};

export default EditPage;
