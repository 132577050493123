import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Link = ({ url, children, target, rel, onClick, style, className }) => (
  <a
    href={url}
    target={target}
    rel={rel}
    onClick={onClick}
    style={style}
    className={className}
  >
    {children}
  </a>
);

Link.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
  target: PropTypes.string,
  rel: PropTypes.string,
  onClick: PropTypes.func,
};

Link.defaultProps = {
  url: undefined,
  className: undefined,
  style: undefined,
  target: "_blank",
  rel: "noreferrer",
  onClick: undefined,
};

export const FooterLink = ({ isLast, padding, icon, ...props }) => (
  <Link {...props}>
    {padding && " "}
    <FontAwesomeIcon
      icon={icon}
      color="black"
      className={`${!isLast ? "mr-2" : ""} fa-solid`}
      size="sm"
    />
  </Link>
);

FooterLink.propTypes = {
  icon: PropTypes.object.isRequired,
  isLast: PropTypes.bool,
  padding: PropTypes.bool,
  props: PropTypes.object,
};

FooterLink.defaultProps = {
  isLast: undefined,
  padding: undefined,
  props: undefined,
};

export default Link;
