import PropTypes from "prop-types";
import Button from "../../core/ui/Button";

const CustomEditCell = ({ dataItem, enterEdit, editField, update, cancel }) => {
  //   const [inEdit, setInEdit] = useState(true);
  const inEdit = dataItem[editField];
  return inEdit ? (
    <td className="flex flex-row ">
      <Button className="edit-button mr-1 w-20" onClick={update}>
        Update
      </Button>
      <Button
        className="edit-button mr-1 w-20"
        onClick={() => cancel(dataItem)}
      >
        Cancel
      </Button>
    </td>
  ) : (
    <td>
      <Button
        className="edit-button mr-1 w-20"
        onClick={() => enterEdit(dataItem)}
      >
        Edit
      </Button>
    </td>
  );
};

CustomEditCell.propTypes = {
  dataItem: PropTypes.object.isRequired,
  editField: PropTypes.string.isRequired,
  enterEdit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};

export const getEditCell =
  (enterEdit, handleUpdate, handleCancel) => (props) => {
    return (
      <CustomEditCell
        {...props}
        enterEdit={enterEdit}
        update={handleUpdate}
        cancel={handleCancel}
        editField="inEdit"
      />
    );
  };
