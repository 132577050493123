import { GET_TASK_HISTORY } from "../actions/actions";

const initialState = {
  data: [],
};

const cronReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_TASK_HISTORY: {
      return {
        ...state,
        data: payload,
      };
    }
    default:
      return state;
  }
};

export default cronReducer;
