import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import FedGrid from "../../core/ui/Grid";
import NodeThunk from "../../../api/thunk/NodeThunk";
import LoadingPanel from "../../core/ui/Loader";
import ToolBox from "../ToolBox";
import DialogBox from "../../core/ui/DialogBox";
import {
  SET_NODES_COLUMNS,
  SET_SEARCH_STRING,
} from "../../../store/actions/actions";
import { getEditCell } from "./editCell";
import { DIALOG_MESSAGE } from "../../../referenceData";

const NodeGrid = ({
  loadNodes,
  nodes,
  loader,
  totalNodes,
  searchString,
  columns,
  setColumns,
  updateNode,
  setSearchString,
}) => {
  const [data, setData] = useState([]);
  // const [editID, setEditID] = useState(null);
  // const [categories, setCategories] = useState([]);
  const [editData, setEditData] = useState({});
  const [showDialog, setDiaglogStatus] = useState(false);
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 10,
  });

  useEffect(() => {
    setSearchString("");
  }, []);

  useEffect(() => {
    setColumns();
  }, [setColumns]);

  useEffect(() => {
    setData(nodes);
  }, [nodes]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      loadNodes({
        rows: dataState.take,
        page: dataState.skip / dataState.take + 1,
        search: searchString,
      });
    }, 500);

    return () => clearTimeout(timeout);
  }, [loadNodes, dataState.take, dataState.skip, searchString]);

  const onPageChange = (event) => {
    setDataState(event.page);
  };
  const enterEdit = (dataItem) => {
    if (editData.editId !== undefined) {
      setDiaglogStatus(true);
      return;
    }
    setEditData((prev) => ({
      ...prev,
      editId: dataItem.accountId,
      category: dataItem.category,
    }));
  };

  const handleUpdate = () => {
    setDiaglogStatus(true);
  };

  const handleCancel = (dataItem) => {
    const newData = data.map((item) =>
      item.accountId === dataItem.accountId
        ? { ...item, category: editData.category }
        : item
    );
    setData(newData);
    setEditData({});
  };
  const itemChange = (event) => {
    const inEditID = event.dataItem.accountId;
    const field = event.field || "";
    const newData = data.map((item) =>
      item.accountId === inEditID ? { ...item, [field]: event.value } : item
    );
    setData(newData);
  };
  return (
    <div>
      {loader && <LoadingPanel />}
      {showDialog && (
        <DialogBox
          dialogMessage={DIALOG_MESSAGE.UPDATE}
          onSaveToggle={() => {
            setDiaglogStatus(false);
            updateNode(
              data.filter((item) => {
                return item.accountId === editData.editId;
              })[0],
              setEditData
            );
          }}
          onCancelToggle={() => {
            setDiaglogStatus(false);
          }}
        />
      )}
      <FedGrid
        style={{
          height: "calc(100vh - 180px)",
          marginRight: "50px",
          marginLeft: "50px",
        }}
        data={data.map((item) => {
          return {
            ...item,
            inEdit: item.accountId === editData.editId,
          };
        })}
        pageable
        dataState={dataState}
        totalRows={totalNodes}
        columns={columns}
        onPageChange={onPageChange}
        onItemChange={itemChange}
      >
        <GridToolbar>
          <ToolBox resetState={setDataState} />
        </GridToolbar>
        {columns.map((column) => {
          return (
            <GridColumn
              key={column.field}
              field={column.field}
              title={column.title}
              editable={column.editable}
              cell={column.customCell ? column.customCell : null}
              width={column.isSelected ? column.width : 0}
            />
          );
        })}
        <GridColumn
          cell={getEditCell(enterEdit, handleUpdate, handleCancel)}
          width={200}
        />
      </FedGrid>
    </div>
  );
};

NodeGrid.propTypes = {
  loadNodes: PropTypes.func.isRequired,
  nodes: PropTypes.array,
  loader: PropTypes.bool,
  totalNodes: PropTypes.number,
  searchString: PropTypes.string,
  columns: PropTypes.array.isRequired,
  setColumns: PropTypes.func.isRequired,
  updateNode: PropTypes.func.isRequired,
  setSearchString: PropTypes.func,
};

NodeGrid.defaultProps = {
  nodes: [],
  loader: false,
  totalNodes: 0,
  searchString: undefined,
  setSearchString: undefined,
};

const mapStoreToProps = (store) => ({
  nodes: store.nodes.data,
  loader: store.common.loader,
  totalNodes: store.nodes.totalNodes,
  searchString: store.common.searchString,
  columns: store.common.columns,
});

const mapDispatchToProps = (dispatch) => ({
  loadNodes: (params) => dispatch(NodeThunk.getAllNodes(params)),
  updateNode: (updatedNode, setEditID) =>
    dispatch(NodeThunk.updateNode(updatedNode, setEditID)),
  setColumns: () => dispatch({ type: SET_NODES_COLUMNS }),
  setSearchString: (searchString) => {
    dispatch({ type: SET_SEARCH_STRING, payload: { searchString } });
  },
});

export default connect(mapStoreToProps, mapDispatchToProps)(NodeGrid);
