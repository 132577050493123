import {
  SHOW_LOADER,
  HIDE_LOADER,
  SET_SEARCH_STRING,
  SET_NODES_COLUMNS,
  SET_COLUMN_PREFERENCES,
  SET_RELATION_COLUMNS,
  SET_X_Y,
} from "../actions/actions";

import nodeColumns from "../../Components/features/NodeGrid/nodeConfig";
import relationColumns from "../../Components/features/RelationGrid/relationConfig";

const initialState = {
  loader: false,
  searchString: undefined,
  columns: [],
};

const commonReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_LOADER:
      return {
        ...state,
        loader: true,
      };
    case HIDE_LOADER:
      return {
        ...state,
        loader: false,
      };
    case SET_SEARCH_STRING:
      return {
        ...state,
        searchString: payload.searchString,
      };
    case SET_NODES_COLUMNS:
      return {
        ...state,
        columns: nodeColumns,
      };
    case SET_RELATION_COLUMNS:
      return {
        ...state,
        columns: relationColumns,
      };
    case SET_X_Y:
      return {
        ...state,
      };
    case SET_COLUMN_PREFERENCES: {
      const newColumns = state.columns.map((column) => {
        if (column.field === payload.field) {
          return {
            ...column,
            isSelected: payload.isSelected,
          };
        }
        return column;
      });
      return {
        ...state,
        columns: newColumns,
      };
    }
    default:
      return state;
  }
};

export default commonReducer;
