// Actions for Node Data
export const GET_ALL_NODES = "GET_ALL_NODES";
export const SET_NODES_COLUMNS = "SET_NODES_COLUMNS";
export const UPDATE_NODES = "UPDATE_NODES";
export const SET_X_Y = "SET_X_Y";

// Actions for Relation Data
export const GET_ALL_RELATION = "GET_ALL_RELATION";
export const SET_RELATION_COLUMNS = "SET_RELATION_COLUMNS";
export const ADD_RELATION = "ADD_RELATION";
export const SET_PROVIDER_DROPDOWN = "SET_PROVIDER_DROPDOWN";
export const SET_CONSUMER_DROPDOWN = "SET_CONSUMER_DROPDOWN";
export const UPDATE_RELATIONS = "UPDATE_RELATIONS";
export const DELETE_RELATIONS = "DELETE_RELATIONS";
export const SET_SEARCH_STRING = "SET_SEARCH_STRING";
export const SET_COLUMN_PREFERENCES = "SET_COLUMN_PREFRENCES";

export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const SET_SUCCESS = "SET_SUCEESS";
export const SET_ERROR = "SET_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const CLEAR_SUCCESS = "CLEAR_SUCCESS";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICAITON";

export const GET_TASK_HISTORY = "GET_TASK_HISTORY";
