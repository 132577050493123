import PropType from "prop-types";
import { connect } from "react-redux";
import Header from "./Header";
import Footer from "./Footer";
import { META } from "../../../referenceData";
import Notification from "./Notification";

const Layout = ({ children, meta, success, error }) => (
  <>
    <Header meta={meta} />
    {children}
    <Footer />
    {(success || error) && <Notification />}
  </>
);

Layout.propTypes = {
  children: PropType.node.isRequired,
  meta: PropType.string,
  success: PropType.bool,
  error: PropType.bool,
};

Layout.defaultProps = {
  meta: META.GRID,
  success: false,
  error: false,
};

const mapStoreToProps = (store) => ({
  success: store.notification.success,
  error: store.notification.error,
});

const mapDispatchToProps = () => ({});

export default connect(mapStoreToProps, mapDispatchToProps)(Layout);
