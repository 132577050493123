import {
  CLEAR_ERROR,
  CLEAR_NOTIFICATION,
  CLEAR_SUCCESS,
  SET_ERROR,
  SET_SUCCESS,
} from "./actions";

export const setSuccess = (payload) => ({ type: SET_SUCCESS, payload });
export const setError = (payload) => ({ type: SET_ERROR, payload });
export const clearSuccess = () => ({ type: CLEAR_SUCCESS });
export const clearError = () => ({ type: CLEAR_ERROR });
export const clearNotification = () => ({
  type: CLEAR_NOTIFICATION,
});
