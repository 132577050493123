import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useOktaAuth } from "@okta/okta-react";
import { useHistory } from "react-router-dom";
// import { Dropdown, DropdownButton } from "react-bootstrap";
import {
  DropDownButton,
  DropDownButtonItem,
} from "@progress/kendo-react-buttons";
import "../../../styles/Header.css";
import { useDispatch } from "react-redux";
import NavLink from "../ui/NavLink";
import { META, ROUTES } from "../../../referenceData";
import AuthService from "../../../api/auth-service";
import Button from "../ui/Button";
import NodeThunk from "../../../api/thunk/NodeThunk";

const Header = ({ meta }) => {
  const dispatch = useDispatch();
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState();
  const history = useHistory();
  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    } else {
      setUserInfo(null);
    }
  }, [authState, oktaAuth]);

  const generateXY = () => {
    dispatch(NodeThunk.generateXY());
  };

  const logout = () => {
    if (authState && authState.isAuthenticated) {
      AuthService.revokeTokens();
      history.push(ROUTES.HOME);
    }
  };

  return (
    <header className="header">
      <div className="navPanel flex justify-content-center h-100">
        <a href={ROUTES.HOME} className="brandLink">
          <div className="navbrand">
            <img className="logo" src="/images/logo.png" alt="FED_EDGE Logo" />
            <h1 className="logo-name">FedEdge</h1>
          </div>
        </a>
        <div className="navLinks">
          {meta === META.EDIT && (
            <NavLink
              to={ROUTES.EDIT}
              className="navItem flex justify-content-center align-items-center h-100"
            >
              <p className="flex align-items-center px-3 my-0 h-100">EDIT</p>
            </NavLink>
          )}
          {meta === META.GRID && (
            <>
              <NavLink
                to={ROUTES.HOME}
                className="navItem flex justify-content-center align-items-center h-100"
              >
                <p className="flex align-items-center px-3 my-0 h-100">Nodes</p>
              </NavLink>
              <NavLink
                to={ROUTES.RELATION}
                className="navItem flex justify-content-center align-items-center h-100"
              >
                <p className="flex align-items-center px-3 mb-0 h-100">
                  Relations
                </p>
              </NavLink>
            </>
          )}
        </div>
      </div>
      <div className="userProfile">
        <Button className="gen-xy-button" onClick={generateXY}>
          Generate X & Y
        </Button>
        <DropDownButton
          className="displayName"
          iconClass="k-icon k-i-arrow-60-down k-icon-48"
          text={userInfo && userInfo.name}
          onItemClick={(e) => e.item.onClick()}
        >
          <DropDownButtonItem text="Logout" onClick={logout} />
        </DropDownButton>
      </div>
    </header>
  );
};

Header.propTypes = {
  meta: PropTypes.string,
};

Header.defaultProps = {
  meta: META.GRID,
};

export default Header;
