import PropTypes from "prop-types";
import { Grid } from "@progress/kendo-react-grid";

const FedGrid = ({
  data,
  style,
  dataState,
  totalRows,
  pageable,
  onPageChange,
  children,
  rowClick,
  onItemChange,
}) => {
  return (
    <Grid
      resizable
      reorderable
      style={style}
      data={{ data, total: totalRows }}
      {...dataState}
      pageable={
        pageable
          ? {
              buttonCount: 4,
              pageSize: 20,
              pageSizes: [10, 20, 50],
            }
          : false
      }
      onPageChange={onPageChange}
      editField="inEdit"
      onRowDoubleClick={rowClick}
      onItemChange={onItemChange}
    >
      {children}
    </Grid>
  );
};

FedGrid.propTypes = {
  data: PropTypes.array.isRequired,
  dataState: PropTypes.object,
  totalRows: PropTypes.number,
  pageable: PropTypes.bool,
  onPageChange: PropTypes.func,
  children: PropTypes.node,
  style: PropTypes.object,
  rowClick: PropTypes.func,
  onItemChange: PropTypes.func,
};

FedGrid.defaultProps = {
  dataState: {},
  totalRows: 0,
  pageable: false,
  onPageChange: null,
  children: null,
  rowClick: null,
  onItemChange: null,
  style: {},
};

export default FedGrid;
