export const NOTIFICATION_AUTOCLOSE_TIME = 1000 * 3; // 5 seconds

export const ROUTES = {
  HOME: "/",
  RELATION: "/relations",
  LOGIN: "/login",
  EDIT: "/edit",
  JOBS: "/jobs",
};

export const ACTIVE_TAB = "activeTabs";
export const META = {
  GRID: "GRID",
  EDIT: "EDIT",
  JOBS: "JOBS",
};

export const categories = [
  "MILITARY",
  "PUBLIC TRANSIT",
  "PUBLIC EDUCATION",
  "INFRASTRUCTURE",
  "LAW ENFORCEMENT",
];

export const VALIDATION = {
  DEFAULT: "Required Field: Please Enter the value",
  PROVIDER_ACCOUNT_ID: "Invalid: Please Enter the Provider Account Name",
  CONSUMER_ACCOUNT_ID: "Invalid: Please Enter the Consumer Account Name",
};

export const PLACEHOLDER_ACCOUNT_NAME = "Enter Salesforce Account Name";
export const DIALOG_MESSAGE = {
  UPDATE: "Are you sure you want to update?",
  DELETE: "Are you sure you want to delete?",
};

export const NOTIFICATION_MESSAGE = {
  SEARCH_FAILURE_MESSAGE: "Your search did not match with any value in nodes",
  SUCESSFULLY_UPDATED: "Successfully Updated",
  FAILED_TO_UPDATE: "Failed to Update",
  PROVIDER_CONSUMER_DUPLICATE: "Provider and Consumer cannot be same",
  INSERT_FAILED_ALREADY_EXIST: "Insert failed as Relation Already Exists",
  INSERT_FAILED: "Failed to add Relationship",
  SUCESSFULLY_INSERTED_RELATION: "Successfully Added",
  UPDATE_FAILED_ALREADY_EXIST: "Update failed as Relation Already Exists",
  RELATION_UPDATED_SUCESSFUL: "Relation Updated Successfully",
  RELATION_DELETED_SUCESSFUL: "Relation deleted successfully",
  RELATION_DELETED_FAILED: "Unable to delete Relation",
  GEN_X_Y_SUCCESS: "Successfully generated X and Y",
  GEN_X_Y_ALREADY_RUNNING: "Procedure in Progress",
  GEN_X_Y_ERROR: "Error while Generation X Y",
};
