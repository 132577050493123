import React from "react";
import { Route } from "react-router-dom";
// import { connect } from "react-redux";
// import PropTypes from "prop-types";
import NodeGrid from "./NodeGrid";
import RelationGrid from "./RelationGrid";
import Layout from "../core/Layout";
import "../../styles/main.css";
import { META, ROUTES } from "../../referenceData";
// import { SET_SEARCH_STRING } from "../../store/actions/actions";

const Home = () => {
  return (
    <Layout meta={META.GRID}>
      <div className="nodegrid">
        <Route exact path={ROUTES.HOME} component={NodeGrid} />
        <Route exact path={ROUTES.RELATION} component={RelationGrid} />
      </div>
    </Layout>
  );
};

// Home.propTypes = {
//   setSearchString: PropTypes.func.isRequired,
// };

// const mapDispatchToProps = (dispatch) => ({
//   setSearchString: (searchString) =>
//     dispatch({ type: SET_SEARCH_STRING, payload: searchString }),
// });

export default Home;
