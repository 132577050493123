import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { GridColumn } from "@progress/kendo-react-grid";
import { Input } from "@progress/kendo-react-inputs";
import FedGrid from "./Grid";
import { PLACEHOLDER_ACCOUNT_NAME, VALIDATION } from "../../../referenceData";
// import Button from "./Button";

const RadioCell = ({ dataItem, handleRadioChange, selectedRow }) => {
  return (
    <td>
      <input
        type="radio"
        value={dataItem.accountId}
        checked={selectedRow === dataItem.accountId}
        onChange={handleRadioChange(dataItem)}
        required
      />
    </td>
  );
};

RadioCell.propTypes = {
  dataItem: PropTypes.any.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  selectedRow: PropTypes.string,
};

RadioCell.defaultProps = {
  selectedRow: undefined,
};

const GridSelect = ({
  label,
  value,
  data,
  columns,
  handleFilterChange,
  dataState,
  stateChange,
  onSelect,
  totalRecords,
  validationMessage,
}) => {
  const target = useRef(null);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const handleChange = (evt) => {
    onSelect(evt.target.value);
    handleFilterChange(evt.target.value);
  };

  const onPageChange = (event) => {
    stateChange(event.page);
  };

  const handleRadioChange = (dataItem) => (evt) => {
    setSelectedRow(evt.target.value);
    onSelect(dataItem);
  };

  const radioCell = (props) => {
    return (
      <RadioCell
        {...props}
        selectedRow={selectedRow}
        handleRadioChange={handleRadioChange}
      />
    );
  };

  return (
    <div
      className="flex w-full relative"
      style={{
        height: "45%",
      }}
    >
      <label
        className="flex flex-column w-full"
        htmlFor="providerSalesforceName"
        style={{
          height: "100%",
        }}
      >
        <div className="w-full">
          <div className="font-bold text-lg">{label}</div>
        </div>
        <div className="w-full flex flex-column overflow-scroll">
          <Input
            type="text"
            className="mr-11 mb-2 border relative p-1"
            value={typeof value === "object" ? value.name || "" : value}
            onChange={handleChange}
            ref={target}
            placeholder={PLACEHOLDER_ACCOUNT_NAME}
            validationMessage={validationMessage}
            required
          />
          <FedGrid
            style={{
              height: "250px",
              marginRight: "50px",
              position: "relative",
            }}
            pageable
            data={data}
            dataState={dataState}
            onPageChange={onPageChange}
            totalRows={totalRecords}
          >
            <GridColumn cell={radioCell} width={30} />
            {columns.map((column) => {
              return (
                <GridColumn
                  key={column.field}
                  field={column.field}
                  title={column.header}
                  width={column.width}
                />
              );
            })}
          </FedGrid>
        </div>
      </label>
    </div>
  );
};

GridSelect.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  dataState: PropTypes.object.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  stateChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  totalRecords: PropTypes.number.isRequired,
  validationMessage: PropTypes.string,
};

GridSelect.defaultProps = {
  validationMessage: VALIDATION.DEFAULT,
};

export default GridSelect;
