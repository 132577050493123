import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

const CustomTitleBar = () => {
  return (
    <div
      className="custom-title"
      style={{
        fontSize: "18px",
        lineHeight: "1.3em",
        fontWeight: "bold",
      }}
    >
      Please Confirm
    </div>
  );
};

const DialogBox = ({ onCancelToggle, onSaveToggle, dialogMessage }) => {
  return (
    <Dialog title={<CustomTitleBar />} onClose={onCancelToggle}>
      <p
        style={{
          margin: "25px",
          fontSize: "18px",
          textAlign: "center",
        }}
      >
        {dialogMessage}
      </p>

      <DialogActionsBar>
        <button
          type="button"
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
          onClick={onCancelToggle}
        >
          Cancel
        </button>
        <button
          type="button"
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
          onClick={onSaveToggle}
        >
          Confirm
        </button>
      </DialogActionsBar>
    </Dialog>
  );
};

DialogBox.propTypes = {
  onCancelToggle: PropTypes.func.isRequired,
  onSaveToggle: PropTypes.func.isRequired,
  dialogMessage: PropTypes.object.isRequired,
};

export default DialogBox;
