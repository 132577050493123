import React from "react";
import PropTypes from "prop-types";
import { DropDownButton } from "@progress/kendo-react-buttons";
import { connect } from "react-redux";
import Search from "./Search";
import ItemRender from "./ItemRender";

const itemRender = (props) => {
  return <ItemRender item={props.item} />;
};

const ToolBar = ({ children, columns, resetState }) => {
  return (
    <div className="toolbox">
      <Search resetState={resetState} />
      <div className="grid-button">
        {children}
        <DropDownButton
          iconClass="k-icon k-i-columns k-icon-48"
          itemRender={itemRender}
          items={columns}
        />
      </div>
    </div>
  );
};

ToolBar.propTypes = {
  children: PropTypes.node,
  resetState: PropTypes.func,
  columns: PropTypes.array.isRequired,
};

ToolBar.defaultProps = {
  children: null,
  resetState: undefined,
};
const mapStoreToProps = (store) => ({
  columns: store.common.columns,
});
export default connect(mapStoreToProps)(ToolBar);
