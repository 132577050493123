import React, { useEffect } from "react";
import { Route, useHistory, Switch } from "react-router-dom";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { SecureRoute, Security, LoginCallback } from "@okta/okta-react";
import "@progress/kendo-theme-default/dist/all.css";
import { Provider } from "react-redux";
import lifecycle from "page-lifecycle";
import Home from "./Components/features/Home";
import store from "./store";
import AuthService from "./api/auth-service";
import Login from "./Components/features/Login";
import { checkLastTabUnload } from "./utils/windowsUtil";
import { ROUTES } from "./referenceData";
import EditPage from "./Components/features/EditPage";

const App = () => {
  const history = useHistory();

  useEffect(() => {
    const handleUnload = checkLastTabUnload(() =>
      AuthService.revokeTokenBeacon()
    );
    lifecycle.addEventListener("statechange", handleUnload);
    return () => lifecycle.removeEventListener("statechange", handleUnload);
  }, []);

  const customAuthHandler = async () => {
    const previousAuthState =
      AuthService.getOktaAuth().authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      AuthService.triggerLogin();
    } else {
      history.push(ROUTES.LOGIN);
    }
  };
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };
  return (
    <Security
      onAuthRequired={customAuthHandler}
      oktaAuth={AuthService.getOktaAuth()}
      restoreOriginalUri={restoreOriginalUri}
    >
      <Provider store={store}>
        <Route path={ROUTES.HOME} component={LoginCallback} />
        <Switch>
          <Route exact path={ROUTES.LOGIN} component={Login} />
          <SecureRoute path={ROUTES.EDIT} component={EditPage} />
          <SecureRoute path={ROUTES.HOME}>
            <Home />
          </SecureRoute>
        </Switch>
      </Provider>
    </Security>
  );
};

export default App;
// 00d26ae35c44f1484cfba58a0f9af4071a9d8d1d4256dd591f32df3525f15d7d
