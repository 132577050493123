import React from "react";
import PropType from "prop-types";
import { Link, useLocation } from "react-router-dom";

const NavLLink = ({ children, to, className }) => {
  const location = useLocation();

  return (
    <Link
      to={to}
      className={
        location.pathname === to ? `${className} active` : `${className}`
      }
    >
      {children}
    </Link>
  );
};

NavLLink.propTypes = {
  children: PropType.node.isRequired,
  to: PropType.string.isRequired,
  className: PropType.string.isRequired,
};

export default NavLLink;
