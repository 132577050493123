import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { GridToolbar, GridColumn } from "@progress/kendo-react-grid";
import RelationThunk from "../../../api/thunk/RelationThunk";
import LoadingPanel from "../../core/ui/Loader";
import ToolBox from "../ToolBox";
import Grid from "../../core/ui/Grid";
import EditForm from "../../core/ui/EditForm";
import {
  SET_RELATION_COLUMNS,
  SET_SEARCH_STRING,
} from "../../../store/actions/actions";
import { DIALOG_MESSAGE, ROUTES } from "../../../referenceData";
import { getEditCell } from "./editCell";
import DialogBox from "../../core/ui/DialogBox";

const RelationGrid = ({
  relations,
  total,
  loader,
  searchString,
  loadRelations,
  columns,
  setRelationColumns,
  deleteRelation,
  setSearchString,
}) => {
  const [data, setData] = useState([]);
  const [showEditForm, setEditForm] = useState(false);
  // const [width, setWidth] = useState()
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 20,
  });
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [deleteItem, setDeleteItem] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setSearchString("");
  }, []);

  useEffect(() => {
    setRelationColumns();
  }, [setRelationColumns]);
  useEffect(() => {
    setData(relations);
  }, [relations]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      loadRelations({
        rows: dataState.take,
        page: dataState.skip / dataState.take + 1,
        search: searchString,
      });
    }, 500);

    return () => clearTimeout(timeout);
  }, [loadRelations, dataState.take, dataState.skip, searchString]);

  const onPageChange = (event) => {
    setDataState(event.page);
  };
  const toggleEditForm = () => {
    history.push({
      pathname: ROUTES.EDIT,
      state: {
        data: [],
      },
    });
  };

  const handleConfirmDialog = (dataItem) => {
    setConfirmDialog((prev) => !prev);
    setDeleteItem(dataItem);
  };

  const handleDelete = () => {
    setConfirmDialog((prev) => !prev);
    deleteRelation(deleteItem.edgeId);
    setDeleteItem([]);
  };

  return (
    <div>
      {loader && <LoadingPanel />}
      {showEditForm && (
        <EditForm
          onFormClose={() => {
            setEditForm(false);
          }}
        />
      )}
      <Grid
        data={data}
        style={{
          height: "calc(100vh - 180px)",
          marginRight: "50px",
          marginLeft: "50px",
        }}
        dataState={dataState}
        pageable
        totalRows={total}
        columns={columns}
        onPageChange={onPageChange}
      >
        <GridToolbar>
          <ToolBox>
            <div>
              <button
                type="button"
                className="add-button mr-1"
                onClick={toggleEditForm}
              >
                Add New
              </button>
            </div>
          </ToolBox>
        </GridToolbar>
        {columns.map((column) => {
          return (
            column.isSelected && (
              <GridColumn
                key={column.field}
                field={column.field}
                title={column.title}
                width={column.isSelected ? column.width : 0}
              />
            )
          );
        })}
        <GridColumn cell={getEditCell(handleConfirmDialog)} width={150} />
      </Grid>
      {confirmDialog && (
        <DialogBox
          dialogMessage={DIALOG_MESSAGE.DELETE}
          onCancelToggle={handleConfirmDialog}
          onSaveToggle={handleDelete}
        />
      )}
    </div>
  );
};

RelationGrid.propTypes = {
  relations: PropTypes.array,
  total: PropTypes.number,
  loadRelations: PropTypes.func.isRequired,
  loader: PropTypes.bool,
  searchString: PropTypes.string,
  columns: PropTypes.array.isRequired,
  setRelationColumns: PropTypes.func.isRequired,
  deleteRelation: PropTypes.func.isRequired,
  setSearchString: PropTypes.func,
};

RelationGrid.defaultProps = {
  relations: [],
  total: 0,
  loader: false,
  searchString: "",
  setSearchString: undefined,
};

const mapStoreToProps = (store) => ({
  relations: store.relations.data,
  total: store.relations.totalRecords,
  loader: store.common.loader,
  searchString: store.common.searchString,
  columns: store.common.columns,
});

const mapDispatchToProps = (dispatch) => ({
  loadRelations: (params) => dispatch(RelationThunk.getRelations(params)),
  setRelationColumns: () => dispatch({ type: SET_RELATION_COLUMNS }),
  deleteRelation: (edgeId) => dispatch(RelationThunk.deleteRelation(edgeId)),
  setSearchString: (searchString) => {
    dispatch({ type: SET_SEARCH_STRING, payload: { searchString } });
  },
});

export default connect(mapStoreToProps, mapDispatchToProps)(RelationGrid);
