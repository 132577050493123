import React, { useState } from "react";
import PropTypes from "prop-types";
import { Checkbox } from "@progress/kendo-react-inputs";
import { connect } from "react-redux";
import { SET_COLUMN_PREFERENCES } from "../../../store/actions/actions";

const ItemRender = ({ item, selectColumns }) => {
  const [value, setValue] = useState(item.isSelected);

  return (
    <Checkbox
      name={item.field}
      value={value}
      onChange={(e) => {
        selectColumns({ field: e.target.name, isSelected: e.value });
      }}
      label={item.title}
      onClick={(e) => {
        e.stopPropagation();
        setValue(!value);
      }}
    />
  );
};

ItemRender.propTypes = {
  item: PropTypes.any.isRequired,
  selectColumns: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  selectColumns: ({ field, isSelected }) =>
    dispatch({ type: SET_COLUMN_PREFERENCES, payload: { field, isSelected } }),
});

export default connect(undefined, mapDispatchToProps)(ItemRender);
