import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { SET_SEARCH_STRING } from "../../../store/actions/actions";
// import Button from "../../core/ui/Button";

const Search = ({ setSearchString, resetState }) => {
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setSearchString("");
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      resetState((prevState) => ({
        ...prevState,
        skip: 0,
      }));
      setSearchString(searchText);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [searchText]);

  const onChangeText = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <div className="search-bar flex">
      <input
        className="inputField p-2"
        type="text"
        name="SearchText"
        placeholder="Enter text to Search"
        value={searchText}
        onChange={onChangeText}
      />
      {/* <Button className="search-button" onClick={onSearch}>
        Search
      </Button> */}
    </div>
  );
};

Search.propTypes = {
  setSearchString: PropTypes.func.isRequired,
  resetState: PropTypes.func,
};

Search.defaultProps = {
  resetState: () => {},
};
// const mapStoreToProps = (store) => ({
//   searchString: store.nodes.searchString,
// });

const mapDispatchToProps = (dispatch) => ({
  setSearchString: (searchString) => {
    dispatch({ type: SET_SEARCH_STRING, payload: { searchString } });
  },
});

export default connect(undefined, mapDispatchToProps)(Search);
