import {
  CLEAR_ERROR,
  CLEAR_NOTIFICATION,
  CLEAR_SUCCESS,
  SET_ERROR,
  SET_SUCCESS,
} from "../actions/actions";

const initialState = {
  success: false,
  successMessage: "",
  error: false,
  errorMessage: "",
};
const notificationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SUCCESS:
      return {
        ...state,
        success: true,
        successMessage: payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: payload,
      };
    case CLEAR_SUCCESS:
      return {
        ...state,
        success: false,
        successMessage: "",
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: false,
        errorMessage: "",
      };
    case CLEAR_NOTIFICATION:
      return initialState;
    default:
      return state;
  }
};

export default notificationReducer;
