import { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../referenceData";
import Button from "../../core/ui/Button";

const CustomEditCell = ({ dataItem, toggleDeleteDialog }) => {
  const history = useHistory();
  const [inEdit, setInEdit] = useState(true);
  const editCol = () => {
    setInEdit(true);
    const selectedRow = dataItem;
    history.push({
      pathname: ROUTES.EDIT,
      state: {
        selectedProvider: {
          accountId: selectedRow.providerId,
          name: selectedRow.providerName,
        },
        selectedConsumer: {
          accountId: selectedRow.consumerId,
          name: selectedRow.consumerName,
        },
        edgeId: selectedRow.edgeId,
        inEdit,
      },
    });
  };
  return (
    <td className="flex flex-row ">
      <Button className="edit-button mr-1 w-20" onClick={editCol}>
        Edit
      </Button>
      <Button
        className="edit-button w-20"
        onClick={() => toggleDeleteDialog(dataItem)}
      >
        Delete
      </Button>
    </td>
  );
};

CustomEditCell.propTypes = {
  dataItem: PropTypes.object.isRequired,
  toggleDeleteDialog: PropTypes.func.isRequired,
};

export const getEditCell = (handleConfirmDialog) => (props) => {
  return <CustomEditCell {...props} toggleDeleteDialog={handleConfirmDialog} />;
};
