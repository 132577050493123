// import _ from "lodash";
import axios from "../axios";
import {
  GET_ALL_NODES,
  HIDE_LOADER,
  SET_X_Y,
  SHOW_LOADER,
  UPDATE_NODES,
} from "../../store/actions/actions";
import { setError, setSuccess } from "../../store/actions/actionCreator";
import { NOTIFICATION_MESSAGE } from "../../referenceData";
// import data from "../../referenceData/data.json";

const getAllNodes = (params) => async (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  axios
    .get("/node/all", {
      params,
    })
    .then((response) => {
      if (response.data.rows.length > 0) {
        dispatch({
          type: GET_ALL_NODES,
          payload: {
            nodes: response.data.rows,
            totalNodes: response.data.totalRecords,
          },
        });
        dispatch({ type: HIDE_LOADER });
      }
    })
    .catch(() => {
      dispatch({
        type: GET_ALL_NODES,
        payload: {
          relations: [],
          totalRecords: 0,
        },
      });
      dispatch({ type: HIDE_LOADER });
      dispatch(setError(NOTIFICATION_MESSAGE.SEARCH_FAILURE_MESSAGE));
    });
};

const updateNode = (newDataItem, setEditID) => async (dispatch, getState) => {
  const currState = getState().nodes.data;
  const currRow = currState.filter((item) => {
    return item.accountId === newDataItem.accountId;
  });
  dispatch({ type: SHOW_LOADER });

  const payload = {
    category: newDataItem.category === null ? [] : newDataItem.category,
  };
  const config = {
    headers: {
      "content-type": "application/json",
    },
  };
  axios
    .patch(`/node/${newDataItem.accountId}`, payload, config)
    .then((response) => {
      if (currRow === response.data.node) {
        dispatch({ type: HIDE_LOADER });
        return;
      }
      dispatch({
        type: UPDATE_NODES,
        payload: {
          updateNode: response.data.node,
          accountId: newDataItem.accountId,
        },
      });
      setEditID({});
      dispatch(setSuccess(NOTIFICATION_MESSAGE.SUCESSFULLY_UPDATED));
      dispatch({ type: HIDE_LOADER });
    })
    .catch(() => {
      dispatch({ type: HIDE_LOADER });
      dispatch(setError(NOTIFICATION_MESSAGE.FAILED_TO_UPDATE));
    });
};

const getNodeByName = (params, actionType) => async (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  axios
    .get("/node/all", {
      params,
    })
    .then((resp) => {
      dispatch({
        type: actionType,
        payload: {
          dropDownList: resp.data.rows,
          total: resp.data.totalRecords,
        },
      });
      dispatch({ type: HIDE_LOADER });
    })
    .catch(() => {
      dispatch({
        type: actionType,
        payload: {
          dropDownList: [],
        },
      });
      dispatch({ type: HIDE_LOADER });
    });
};

const generateXY = () => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  axios
    .post("/node/genXY")
    .then((res) => {
      const { data } = res;
      dispatch({
        type: SET_X_Y,
      });
      window.location.reload();
      dispatch({ type: HIDE_LOADER });
      if (data.toLowerCase() === "already running") {
        dispatch(setError(NOTIFICATION_MESSAGE.GEN_X_Y_ALREADY_RUNNING));
        return;
      }
      dispatch(setSuccess(NOTIFICATION_MESSAGE.GEN_X_Y_SUCCESS));
    })
    .catch(() => {
      dispatch(setError(NOTIFICATION_MESSAGE.GEN_X_Y_ERROR));
      dispatch({ type: HIDE_LOADER });
    });
};

export default { getAllNodes, updateNode, getNodeByName, generateXY };
