import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useHistory, useLocation } from "react-router-dom";
import {
  SET_PROVIDER_DROPDOWN,
  SET_CONSUMER_DROPDOWN,
} from "../../../store/actions/actions";
import NodeThunk from "../../../api/thunk/NodeThunk";
import RelationThunk from "../../../api/thunk/RelationThunk";
import GridSelect from "./GridSelection";
import { dropDownColumns } from "../../features/RelationGrid/dropDownConfig";
import Button from "./Button";
import { ROUTES, VALIDATION } from "../../../referenceData";
import { setError } from "../../../store/actions/actionCreator";
import LoadingPanel from "./Loader";

const EditForm = ({
  setDropList,
  consumerDropDown,
  providerDropDown,
  insertIntoRelation,
  loader,
  clearDropDown,
  setEditDropDown,
  updateRelation,
  totalConsumerDropDown,
  totalProviderDropDown,
  validationError,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { inEdit, edgeId, selectedConsumer, selectedProvider } =
    location.state || {};
  const [provider, setProvider] = useState(selectedProvider || "");
  const [consumer, setConsumer] = useState(selectedConsumer || "");
  const [filter, setFilter] = useState();
  const [select, setSelect] = useState();
  const [providerDataState, setProviderState] = useState({
    skip: 0,
    take: 10,
  });
  const [consumerDataState, setConsumerState] = useState({
    skip: 0,
    take: 10,
  });
  useEffect(() => {
    clearDropDown(SET_CONSUMER_DROPDOWN);
    clearDropDown(SET_PROVIDER_DROPDOWN);
  }, []);

  useEffect(() => {
    setEditDropDown(
      SET_PROVIDER_DROPDOWN,
      selectedProvider ? [selectedProvider] : []
    );
    setEditDropDown(
      SET_CONSUMER_DROPDOWN,
      selectedConsumer ? [selectedConsumer] : []
    );
  }, []);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (select === "Provider") {
        setDropList(
          {
            rows: providerDataState.take,
            page: providerDataState.skip / providerDataState.take + 1,
            name: filter,
          },
          SET_PROVIDER_DROPDOWN
        );
      }
      if (select === "Consumer") {
        setDropList(
          {
            rows: consumerDataState.take,
            page: consumerDataState.skip / consumerDataState.take + 1,
            name: filter,
          },
          SET_CONSUMER_DROPDOWN
        );
      }
    }, 1000);

    return () => clearTimeout(timeout);
  }, [providerDataState, consumerDataState, filter]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (provider.accountId === undefined || consumer.accountId === undefined) {
      validationError("Provider or Consumer not selected");
      return;
    }
    insertIntoRelation({
      providerId: provider.accountId,
      consumerId: consumer.accountId,
      history,
    });
  };
  const editSubmit = (e) => {
    e.preventDefault();
    if (provider.accountId === undefined || consumer.accountId === undefined) {
      validationError("Provider or Consumer not selected");
      return;
    }
    updateRelation({
      providerId: provider.accountId,
      consumerId: consumer.accountId,
      edgeId,
      history,
    });
  };
  return (
    <div
      className="flex flex-column justify-content-start ml-3"
      style={{
        height: "100%",
      }}
    >
      <form onSubmit={inEdit ? editSubmit : handleSubmit}>
        <GridSelect
          label="Provider Salesforce Account Name"
          data={providerDropDown}
          value={provider}
          columns={dropDownColumns}
          onSelect={setProvider}
          filter={filter}
          dataState={providerDataState}
          stateChange={setProviderState}
          handleFilterChange={(name) => {
            setSelect("Provider");
            setFilter(name);
          }}
          validationMessage={VALIDATION.PROVIDER_ACCOUNT_ID}
          totalRecords={totalProviderDropDown}
        />
        <GridSelect
          label="Consumer Salesforce Account Name"
          data={consumerDropDown}
          value={consumer}
          columns={dropDownColumns}
          onSelect={setConsumer}
          filter={filter}
          dataState={consumerDataState}
          stateChange={setConsumerState}
          handleFilterChange={(name) => {
            setSelect("Consumer");
            setFilter(name);
          }}
          validationMessage={VALIDATION.CONSUMER_ACCOUNT_ID}
          totalRecords={totalConsumerDropDown}
        />

        <div className="flex flex-row w-100 mt-4 h-[5%]">
          <div>
            <Button
              type
              className="submit-button"
              isDisabled={
                inEdit
                  ? provider.accountId === selectedProvider.accountId &&
                    consumer.accountId === selectedConsumer.accountId
                  : false
              }
            >
              {inEdit ? "Edit" : "Submit"}
            </Button>
            <Button
              className="goback-button ml-4"
              onClick={() => history.push(ROUTES.RELATION)}
            >
              Cancel
            </Button>
          </div>
          {loader && <LoadingPanel />}
        </div>
      </form>
    </div>
  );
};
EditForm.propTypes = {
  providerDropDown: PropTypes.array,
  consumerDropDown: PropTypes.array,
  setDropList: PropTypes.func.isRequired,
  insertIntoRelation: PropTypes.func.isRequired,
  clearDropDown: PropTypes.func.isRequired,
  setEditDropDown: PropTypes.func.isRequired,
  updateRelation: PropTypes.func.isRequired,
  loader: PropTypes.bool,
  totalProviderDropDown: PropTypes.number,
  totalConsumerDropDown: PropTypes.number,
  validationError: PropTypes.func.isRequired,
};

EditForm.defaultProps = {
  providerDropDown: [],
  consumerDropDown: [],
  totalProviderDropDown: 0,
  totalConsumerDropDown: 0,
  loader: false,
};

const mapStoreToProps = (state) => ({
  loader: state.common.loader,
  providerDropDown: state.relations.providerDropDown,
  consumerDropDown: state.relations.consumerDropDown,
  totalConsumerDropDown: state.relations.totalConsumerDropDown,
  totalProviderDropDown: state.relations.totalProviderDropDown,
});

const mapDispatchToProps = (dispatch) => ({
  setDropList: (params, actiontype) =>
    dispatch(NodeThunk.getNodeByName(params, actiontype)),
  insertIntoRelation: (params) => dispatch(RelationThunk.addRelations(params)),
  clearDropDown: (actionType) => dispatch({ type: actionType, payload: [] }),
  setEditDropDown: (actionType, data) =>
    dispatch({ type: actionType, payload: { dropDownList: data } }),
  updateRelation: (params) => dispatch(RelationThunk.updateRelations(params)),
  validationError: (message) => dispatch(setError(message)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(EditForm);
