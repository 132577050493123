import React from "react";
import "../../../styles/Footer.css";
import {
  faFacebook,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FooterLink } from "./Link";
import { getCurrentYear } from "../../../utils/dateUtils";

const Footer = () => {
  return (
    <div className="flex flex-column fixed-bottom w-100">
      <div className="bg-200 p-2 footer">
        <div className="flex flex-column text-center text-m text-black">
          <p className="mb-2">
            © {getCurrentYear()} Snowflake Inc. All Rights Reserved
          </p>
          <div className="flex justify-content-center">
            <FooterLink
              url="https://twitter.com/SnowflakeDB"
              icon={faTwitter}
              padding
            />
            <FooterLink
              url="https://www.linkedin.com/company/3653845"
              icon={faLinkedin}
              padding
            />
            <FooterLink
              url="https://www.youtube.com/user/snowflakecomputing"
              icon={faYoutube}
            />
            <FooterLink
              url="https://www.facebook.com/Snowflake-Computing-709171695819345/"
              icon={faFacebook}
              isLast
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
